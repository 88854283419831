
  import { Scenes } from '@/constants'
  import { MathUtils } from 'three'
  import { Component } from 'vue-property-decorator'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component
  export default class PorchScene extends BaseScene {
    name = Scenes.PORCH

    async setup() {
      if (this.disposed) return

      // console.time(this.log('setup'))

      this.setupObjects()
      this.setupSprites()
      this.setupClouds()
      this.setupDoors()

      this.update(this.settings)

      this.resize()

      // console.timeEnd(this.log('setup'))
    }

    tick({ time, delta }: any) {
      if (this.disposed) return

      const { objects, clouds, door } = this.$refs
      const { bottle, waterCan } = objects

      if (clouds !== undefined) {
        for (const uid in clouds) {
          const cloud = clouds[uid]
          cloud.uniforms.uTime.value += delta
        }
      }

      // bottle

      bottle.position.y = bottle.origin.y + Math.sin(time * 1) * 0.00015
      bottle.rotation.y = Math.sin(time * 1) * 0.1
      bottle.rotation.z = Math.PI + Math.cos(time * 0.5) * 0.05

      const bottleFloorDistance = bottle.position.distanceTo(bottle.floor)
      bottle.shadow.material.opacity = MathUtils.mapLinear(bottleFloorDistance, 0.002, 0.0017, 1, 0.75)
      bottle.shadow.scale.x = MathUtils.mapLinear(bottleFloorDistance, 0.002, 0.0017, 0.001, 0.002)

      // waterCan

      waterCan.position.y = waterCan.origin.y + Math.cos(time * 1) * 0.0002
      waterCan.rotation.x = Math.cos(time * 1) * 0.1
      waterCan.rotation.y = Math.cos(time * 1) * 0.1
      waterCan.rotation.z = Math.sin(time * 0.5) * 0.1

      const waterCanFloorDistance = waterCan.position.distanceTo(waterCan.floor)
      waterCan.shadow.material.opacity = MathUtils.mapLinear(waterCanFloorDistance, 0.0022, 0.0018, 1, 0.75)
      waterCan.shadow.scale.x = MathUtils.mapLinear(waterCanFloorDistance, 0.0022, 0.0018, 0.001, 0.002)

      // door

      door.portal.uniforms.uTime.value += delta
    }
  }
