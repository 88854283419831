import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import { AdditiveBlending, BufferAttribute, BufferGeometry, Points, Scene, ShaderMaterial } from 'three'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { Events } from '@/constants'

class StarlightGeometry extends BufferGeometry {
  constructor() {
    super()

    const count = 150

    const positions = new Float32Array(count * 3)
    const uids = new Float32Array(count * 1)
    const dirs = new Float32Array(count * 1)

    for (let i = 0; i < count; i++) {
      positions[i * 3 + 0] = (Math.random() - 0.5) * 0.15
      positions[i * 3 + 1] = (Math.random() - 0.25) * 0.01
      positions[i * 3 + 2] = -0.08 + Math.random() * 0.06

      dirs[i] = Math.random() - 0.5
      uids[i] = i / (count - 1)
    }

    this.setAttribute('position', new BufferAttribute(positions, 3))
    this.setAttribute('uid', new BufferAttribute(uids, 1))
    this.setAttribute('dir', new BufferAttribute(dirs, 1))
  }
}

class StarlightMaterial extends ShaderMaterial {
  constructor() {
    super({
      fragmentShader,
      vertexShader,
      uniforms: {
        uTime: { value: 0 },
        uSize: { value: 1 },
        uPixelRatio: { value: 1 },
      },
      blending: AdditiveBlending,
      transparent: true,
      depthWrite: false,
      depthTest: false,
    })
  }
}

@Component
export default class Starlight extends Vue {
  @Prop()
  dpr!: number

  @Inject()
  scene!: Scene

  instance = new Points(new StarlightGeometry(), new StarlightMaterial())

  get uniforms() {
    return this.instance.material.uniforms
  }

  @Watch('dpr')
  resize(dpr: number) {
    this.uniforms.uPixelRatio.value = dpr
  }

  tick({ delta }: any) {
    this.uniforms.uTime.value += delta
  }

  mounted() {
    this.instance.renderOrder = 1

    this.scene.add(this.instance)

    this.$bus.$on(Events.GL.RENDER, this.tick)
  }

  destroyed() {
    this.$bus.$off(Events.GL.RENDER, this.tick)

    this.scene.remove(this.instance)

    this.instance.geometry.dispose()
    this.instance.material.dispose()
  }

  render() {
    return null
  }
}
