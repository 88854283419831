
  import { gsap } from 'gsap'
  import { Getter } from 'vuex-class'
  import { HotspotState } from '@/store/types'
  import { PerspectiveCamera, Vector2, WebGLRenderer } from 'three'
  import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator'

  @Component
  export default class Canvas extends Vue {
    @Getter('bounding')
    bounding!: any

    @Prop()
    mouse!: any

    @Prop()
    zoom!: boolean

    @Prop()
    debug!: boolean

    @Prop()
    viewport!: Vector2

    @Prop()
    hotspots!: HotspotState[]

    @Inject()
    renderer!: WebGLRenderer

    @Inject()
    camera!: PerspectiveCamera

    @Inject()
    cameraDefaultDepth!: number

    @Inject()
    update!: () => void

    @Inject()
    animate!: (time: number) => void

    @Inject()
    dispatch!: (time: number, delta: number) => void

    @Inject()
    @Watch('mouse', { deep: true })
    pointerUpdate!: (mouse: any) => void

    @Inject()
    @Watch('viewport', { deep: true, immediate: true })
    resize!: (viewport: Vector2) => void

    @Inject()
    @Watch('bounding', { deep: true, immediate: true })
    boundingUpdate!: (bounding: any) => void

    @Inject()
    @Watch('debug', { immediate: true })
    debugMode!: (debug: boolean) => void

    @Watch('zoom')
    zoomUpdate(zoom: number) {
      const z = zoom ? -0.008 : this.cameraDefaultDepth
      const ease = zoom ? 'power2.inOut' : 'power2.out'
      gsap.to(this.camera.position, { precision: { z }, duration: 1.8, ease })
    }

    tick(time: number, elapsed: number) {
      const delta = elapsed * 0.001

      this.$stats.begin()

      this.animate(time)

      this.update()

      this.project()

      this.dispatch(time, delta)

      this.$stats.end()
    }

    project() {
      if (this.hotspots) {
        for (const hotspot of this.hotspots) {
          const screenPosition = hotspot.position.clone()
          screenPosition.project(this.camera)

          const x = screenPosition.x * this.viewport.x * 0.5
          const y = -screenPosition.y * this.viewport.y * 0.5

          hotspot.projection.set(x, y)
        }
      }
    }

    mounted() {
      this.$el.appendChild(this.renderer.domElement)

      gsap.ticker.fps(this.$gpu.getFPS())
      gsap.ticker.add(this.tick)
    }

    destroyed() {
      gsap.ticker.remove(this.tick)

      this.$el.removeChild(this.renderer.domElement)
    }
  }
