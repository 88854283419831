import { gsap } from 'gsap'
import { Events } from '@/constants'
import { HotspotState } from '@/store/types'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { BufferAttribute, Mesh, Scene, RawShaderMaterial, Vector3, MathUtils, AdditiveBlending, Color } from 'three'
import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import CustomBufferGeometry from '@/webgl/geometries/CustomBufferGeometry'
import * as cache from '@/services/cache'

class SprinklesGeometry extends CustomBufferGeometry {
  constructor() {
    super()

    const count = 100

    const trianglePositions = [-1, -1, 3, -1, -1, 3]
    const triangleUvs = [0, 0, 2, 0, 0, 2]

    const positions = new Float32Array(count * 3 * 2)
    const uvs = new Float32Array(count * 3 * 2)

    const translates = new Float32Array(count * 3 * 3)
    const rotations = new Float32Array(count * 3 * 3)
    const scales = new Float32Array(count * 3 * 3)
    const colors = new Float32Array(count * 3 * 3)

    const delayDurations = new Float32Array(count * 3 * 2)
    const startPositions = new Float32Array(count * 3 * 3)
    const controlPositions0 = new Float32Array(count * 3 * 3)
    const controlPositions1 = new Float32Array(count * 3 * 3)
    const endPositions = new Float32Array(count * 3 * 3)
    const axisAngles = new Float32Array(count * 3 * 4)

    const axis = new Vector3()

    const color = new Color()

    for (let c = count - 1, i1 = 0, i2 = 0, i3 = 0, i4 = 0; c >= 0; c--) {
      const tx = 0
      const ty = 0
      const tz = 0

      const rx = 0
      const ry = 0
      const rz = 0

      const scale = 1

      const delay = c * 0.0
      const duration = MathUtils.randFloat(1, 2)

      const spx = 0 // MathUtils.randFloat(-.004, .004)
      const spy = 0 // MathUtils.randFloat(.001, .002)
      const spz = 0 // MathUtils.randFloat(-.004, .004)

      const cp0x = MathUtils.randFloat(-0.0015, 0.0015) // MathUtils.randFloat(-.001, .001)
      const cp0y = MathUtils.randFloat(-0.0025, 0.0025) // MathUtils.randFloat(.001, .001)
      const cp0z = MathUtils.randFloat(-0.0015, 0.0015) // MathUtils.randFloat(-.001, .001)

      const cp1x = MathUtils.randFloat(-0.0025, 0.0025) // MathUtils.randFloat(-.003, .003)
      const cp1y = MathUtils.randFloat(-0.0015, 0.0015) // MathUtils.randFloat(.002, .002)
      const cp1z = MathUtils.randFloat(-0.0025, 0.0025) // MathUtils.randFloat(-.003, .003)

      const epx = MathUtils.randFloat(-0.0035, 0.0035) // MathUtils.randFloat(-.004, .004)
      const epy = MathUtils.randFloat(-0.0, 0.0025) // MathUtils.randFloat(.001, .001)
      const epz = MathUtils.randFloat(-0.0025, 0.0035) // MathUtils.randFloat(-.004, .004)

      // rotation
      axis.x = 0 // MathUtils.randFloatSpread(1)
      axis.y = 0 // MathUtils.randFloatSpread(1)
      axis.z = MathUtils.randFloatSpread(1)
      axis.normalize()
      const angle = Math.PI * MathUtils.randInt(2, 3)

      color.set('#ffffff')
      //color.set(c % 4 === 3 ? '#f03fa9' : c % 4 === 2 ? '#228bcf' : c % 3 === 1 ? '#f7a0ab': '#ffffff')

      for (let i = 0; i < 3; i++, i1 += 1, i2 += 2, i3 += 3, i4 += 4) {
        positions[i2 + 0] = trianglePositions[i * 2 + 0]
        positions[i2 + 1] = trianglePositions[i * 2 + 1]

        uvs[i2 + 0] = triangleUvs[i * 2 + 0]
        uvs[i2 + 1] = triangleUvs[i * 2 + 1]

        translates[i3 + 0] = tx
        translates[i3 + 1] = ty
        translates[i3 + 2] = tz

        rotations[i3 + 0] = rx
        rotations[i3 + 1] = ry
        rotations[i3 + 2] = rz

        scales[i3 + 0] = scale
        scales[i3 + 1] = scale
        scales[i3 + 2] = scale

        // delay | duration
        delayDurations[i2 + 0] = delay
        delayDurations[i2 + 1] = duration

        startPositions[i3 + 0] = spx
        startPositions[i3 + 1] = spy
        startPositions[i3 + 2] = spz

        controlPositions0[i3 + 0] = cp0x
        controlPositions0[i3 + 1] = cp0y
        controlPositions0[i3 + 2] = cp0z

        controlPositions1[i3 + 0] = cp1x
        controlPositions1[i3 + 1] = cp1y
        controlPositions1[i3 + 2] = cp1z

        endPositions[i3 + 0] = epx
        endPositions[i3 + 1] = epy
        endPositions[i3 + 2] = epz

        // rotation
        axisAngles[i4 + 0] = axis.x
        axisAngles[i4 + 1] = axis.y
        axisAngles[i4 + 2] = axis.z
        axisAngles[i4 + 3] = angle

        // color
        colors[i3 + 0] = color.r
        colors[i3 + 1] = color.g
        colors[i3 + 2] = color.b
      }
    }

    this.setAttribute('position', new BufferAttribute(positions, 2))
    this.setAttribute('uv', new BufferAttribute(uvs, 2))

    this.setAttribute('aTranslate', new BufferAttribute(translates, 3))
    this.setAttribute('aRotation', new BufferAttribute(rotations, 3))
    this.setAttribute('aScale', new BufferAttribute(scales, 3))

    this.setAttribute('aDelayDuration', new BufferAttribute(delayDurations, 2))
    this.setAttribute('aStartPosition', new BufferAttribute(startPositions, 3))
    this.setAttribute('aControlPosition0', new BufferAttribute(controlPositions0, 3))
    this.setAttribute('aControlPosition1', new BufferAttribute(controlPositions1, 3))
    this.setAttribute('aEndPosition', new BufferAttribute(endPositions, 3))
    this.setAttribute('aAxisAngle', new BufferAttribute(axisAngles, 4))

    this.setAttribute('aColor', new BufferAttribute(colors, 3))
  }
}

class SprinklesMaterial extends RawShaderMaterial {
  constructor() {
    super({
      fragmentShader,
      vertexShader,
      uniforms: {
        uTime: { value: 0 },
        uReveal: { value: 0 },
        uScale: { value: 0.00022 },
        uPosition: { value: new Vector3() },
        tDiffuse: { value: cache.get('shared-cache')['map-leaf'] },
      },
      blending: AdditiveBlending,
      transparent: true,
      depthWrite: false,
      depthTest: false,
    })
  }
}

@Component
export default class Sprinkles extends Vue {
  @Prop()
  hotspot!: HotspotState

  @Prop()
  position!: any

  @Prop()
  reveal!: number

  @Inject()
  scene!: Scene

  instance = new Mesh(new SprinklesGeometry(), new SprinklesMaterial())

  get uniforms() {
    return this.instance.material.uniforms
  }

  @Watch('hotspot')
  emitter(hotspot: HotspotState) {
    if (!hotspot) return

    const { position } = hotspot
    this.uniforms.uPosition.value.copy(position)
    gsap.fromTo(this.uniforms.uReveal, { value: 0 }, { value: 2, duration: 6, ease: 'power2.out' })
  }

  @Watch('position', { deep: true, immediate: true })
  positionUpdate({ x, y, z }: any) {
    this.uniforms.uPosition.value.set(x.value, y.value, z.value)
  }

  @Watch('reveal', { immediate: true })
  revealUpdate(reveal: number) {
    this.uniforms.uReveal.value = reveal * 1.25
  }

  tick({ delta }: any) {
    this.uniforms.uTime.value += delta
  }

  mounted() {
    this.instance.renderOrder = 1

    this.scene.add(this.instance)

    this.$bus.$on(Events.GL.RENDER, this.tick)
  }

  destroyed() {
    this.$bus.$off(Events.GL.RENDER, this.tick)

    this.scene.remove(this.instance)

    this.instance.geometry.dispose()
    this.instance.material.dispose()
  }

  render() {
    return null
  }
}
