import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { AmbientLight, DirectionalLight, DirectionalLightHelper, Scene } from 'three'

@Component
export default class Lights extends Vue {
  @Prop()
  ambient!: any

  @Prop()
  directional!: any

  @Prop()
  helpers!: boolean

  @Inject()
  scene!: Scene

  ambientLight = new AmbientLight(0xffffff, 1)

  directionalLight = new DirectionalLight(0xffffff, 1)

  @Watch('ambient', { deep: true, immediate: true })
  ambientUpdate({ color, intensity }: any) {
    this.ambientLight.intensity = intensity.value
    this.ambientLight.color.set(color.value)
  }

  @Watch('directional', { deep: true, immediate: true })
  directionalUpdate({ color, intensity, positionX, positionY, positionZ, lookAtX, lookAtY, lookAtZ }: any) {
    this.directionalLight.intensity = intensity.value
    this.directionalLight.scale.setScalar(0.025)
    this.directionalLight.color.set(color.value)
    this.directionalLight.position.set(positionX.value, positionY.value, positionZ.value)
    this.directionalLight.target.position.set(lookAtX.value, lookAtY.value, lookAtZ.value)
  }

  addHelpers() {
    const helper = new DirectionalLightHelper(this.directionalLight)
    this.scene.add(helper)
    requestAnimationFrame(() => {
      helper.update()
    })
  }

  mounted() {
    this.scene.add(this.directionalLight.target)
    this.scene.add(this.directionalLight)
    this.scene.add(this.ambientLight)

    if (this.helpers) {
      this.addHelpers()
    }
  }

  destroyed() {
    this.scene.remove(this.directionalLight)
    this.scene.remove(this.ambientLight)
  }

  render() {
    return null
  }
}
