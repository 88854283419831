
  import { Scenes } from '@/constants'
  import { MathUtils } from 'three'
  import { Component } from 'vue-property-decorator'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component
  export default class BedroomScene extends BaseScene {
    name = Scenes.BEDROOM

    async setup() {
      if (this.disposed) return

      // console.time(this.log('setup'))

      this.setupObjects()
      this.setupSprites()
      this.setupDoors()

      this.update(this.settings)

      this.resize()

      // console.timeEnd(this.log('setup'))
    }

    tick({ time, delta }: any) {
      if (this.disposed) return

      const { objects, door } = this.$refs
      const { bottle, guitar } = objects

      // bottle

      bottle.position.x = bottle.origin.x + Math.sin(time * 0.5) * 0.05
      bottle.position.y = bottle.origin.y + Math.cos(time * 1) * 0.025
      bottle.rotation.y = Math.cos(time * 0.5) * 0.1
      bottle.rotation.z = Math.cos(time * 1) * 0.025

      const bottleFloorDistance = bottle.position.distanceTo(bottle.floor)
      bottle.shadow.material.opacity = MathUtils.mapLinear(bottleFloorDistance, 1.29, 1.34, 1, 0.75)
      bottle.shadow.scale.x = MathUtils.mapLinear(bottleFloorDistance, 1.29, 1.34, 0.002, 0.003)

      // guitar

      guitar.position.y = guitar.origin.y + Math.cos(time * 1) * 0.05
      guitar.rotation.x = Math.cos(time * 0.5) * 0.2
      guitar.rotation.y = Math.sin(time * 1) * 0.15
      guitar.rotation.z = Math.cos(time * 1) * 0.1

      const guitarFloorDistance = guitar.position.distanceTo(guitar.floor)
      guitar.shadow.material.opacity = MathUtils.mapLinear(guitarFloorDistance, 1.17, 1.27, 1, 0.75)
      guitar.shadow.scale.x = MathUtils.mapLinear(guitarFloorDistance, 1.17, 1.27, 0.002, 0.003)

      // door

      door.portal.uniforms.uTime.value += delta
    }
  }
