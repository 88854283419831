
  import { Scenes } from '@/constants'
  import { MathUtils } from 'three'
  import { Component } from 'vue-property-decorator'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component
  export default class MakeupScene extends BaseScene {
    name = Scenes.MAKEUP

    async setup() {
      if (this.disposed) return

      // console.time(this.log('setup'))

      this.setupObjects()
      this.setupSprites()
      this.setupDoors()

      this.update(this.settings)

      this.resize()

      // console.timeEnd(this.log('setup'))
    }

    tick({ time, delta }: any) {
      if (this.disposed) return

      const { objects, door } = this.$refs
      const { eyeshadow, lipstick } = objects

      // eyeshadow

      eyeshadow.position.y = eyeshadow.origin.y + Math.sin(time) * 0.05
      eyeshadow.rotation.x = 0.2 * Math.PI + Math.cos(time) * 0.05
      eyeshadow.rotation.z = Math.cos(time) * 0.1

      const eyeshadowFloorDistance = eyeshadow.position.distanceTo(eyeshadow.floor)
      eyeshadow.shadow.material.opacity = MathUtils.mapLinear(eyeshadowFloorDistance, 0.79, 0.89, 1, 0.75)
      eyeshadow.shadow.scale.x = MathUtils.mapLinear(eyeshadowFloorDistance, 0.79, 0.89, 0.003, 0.004)

      // lipstick

      lipstick.position.y = lipstick.origin.y + Math.cos(time) * 0.05
      lipstick.rotation.y += delta * 0.6
      lipstick.rotation.z = Math.cos(time) * 0.1

      const lipstickFloorDistance = lipstick.position.distanceTo(lipstick.floor)
      lipstick.shadow.material.opacity = MathUtils.mapLinear(lipstickFloorDistance, 0.85, 0.95, 1, 0.75)
      lipstick.shadow.scale.x = MathUtils.mapLinear(lipstickFloorDistance, 0.85, 0.95, 0.0015, 0.0025)

      // door

      door.portal.uniforms.uTime.value += delta
    }
  }
