import { Vector2, Vector4 } from 'three'

export const dictionary = {
  envMap: [
    'materiale_bottiglietta.001',
    'mat_chitarra',
    'lipstick_mat',
    'eyeshadow_mat',
    'Gucci_Flora_12_FLORA',
    'material_annaffiatoio',
    'material_porta',
  ],

  sprites: {
    bedroom: [
      {
        frames: 'sprite-bedroom-magnolia',
        target: 'model_magnolia',
        uid: 'magnolia',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 52 },
          uSteps: { value: new Vector2(4, 13) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-bedroom-mandarin',
        target: 'model_mandarin',
        uid: 'mandarin',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 54 },
          uSteps: { value: new Vector2(6, 9) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-bedroom-jasmine',
        target: 'model_jasmine',
        uid: 'jasmine',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(6, 4) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-cats',
        target: 'model_gatto',
        uid: 'cat',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-cat-tail-cat-bedroom',
        target: 'model_gatto_letto',
        uid: 'bed-cat',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 8) },
          uOffset: { value: new Vector2(0, 4) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-bedroom-cat-carpet',
        target: 'model_gatto_tappeto',
        uid: 'cat-carpet',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 50 },
          uSteps: { value: new Vector2(5, 10) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-bedroom-red-cat',
        target: 'model_gatto_poltrona',
        uid: 'red-cat',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-bedroom-dog-eyes',
        target: 'model_blink',
        uid: 'dog-eyes',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 22 },
          uSteps: { value: new Vector2(11, 2) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
    ],
    makeup: [
      {
        frames: 'sprite-cats',
        target: 'model_gatto_vasca',
        uid: 'cat-tub',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 3) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: 1,
          duration: 1,
        },
      },
      {
        frames: 'sprite-makeup-cat-carpet-cat-tail',
        target: 'model_gatto_tappeto',
        uid: 'cat-carpet',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-makeup-cat-tail-drop',
        target: 'model_acqua_vasca',
        uid: 'drop',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 18 },
          uSteps: { value: new Vector2(6, 8) },
          uOffset: { value: new Vector2(0, 3) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: 1.2,
          duration: 1,
        },
      },
      {
        frames: 'sprite-makeup-cat-tail-drop',
        target: 'model_gatto_sedia',
        uid: 'cat-tail-1',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 30 },
          uSteps: { value: new Vector2(6, 8) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-makeup-cat-carpet-cat-tail',
        target: 'model_gatto_como',
        uid: 'cat-tail-2',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 14 },
          uSteps: { value: new Vector2(7, 6) },
          uOffset: { value: new Vector2(0, 2) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: 1.2,
          duration: 1,
        },
      },
    ],
    porch: [
      {
        frames: 'sprite-porch-dog',
        target: 'model_dog_garden001',
        uid: 'dog',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 3,
          timeScale: 1,
          duration: 3,
        },
      },
      {
        frames: 'sprite-porch-jasmine-white-gardenia',
        target: 'model_jasmine_garden',
        uid: 'jasmine',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-porch-pear-blossom-accord',
        target: 'model_pear',
        uid: 'pear',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 4) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-porch-jasmine-white-gardenia',
        target: 'model_gardenia',
        uid: 'gardenia',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 3) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-porch-cat-ear',
        target: 'model_gatto_orecchio_garden',
        uid: 'cat-ear',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 4 },
          uSteps: { value: new Vector2(4, 1) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 3,
          timeScale: 1,
          duration: 0.3,
        },
      },
      {
        frames: 'sprite-cat-tail-cat-bedroom',
        target: 'model_gatto_coda_garden',
        uid: 'cat-tail',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 8) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
    ],
  },

  objects: {
    bedroom: [
      {
        uid: 'bottle',
        target: 'model_boccetta_flora_gorgeous_jasmine',
        shadow: 'Shadow_2',
      },
      {
        uid: 'guitar',
        target: 'model_chitarra',
        shadow: 'Shadow_4',
      },
    ],
    makeup: [
      {
        uid: 'eyeshadow',
        target: 'model_eyeshadow',
        shadow: 'Shadow_1',
      },
      {
        uid: 'lipstick',
        target: 'model_rossetto',
        shadow: 'Shadow_2',
      },
    ],
    porch: [
      {
        uid: 'bottle',
        target: 'model_flora_gorgeous_gardenia',
        shadow: 'Shadow_1003',
      },
      {
        uid: 'waterCan',
        target: 'model_annaffiatoio',
        shadow: 'Shadow_1004',
      },
    ],
  },

  clouds: {
    porch: [
      {
        texture: 'map-cloud-1',
        target: 'Cloud_1',
        uid: 'left',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uOffsetParams: { value: new Vector2() },
          uTimeOffset: { value: 1 },
          uTime: { value: 0 },
        },
      },
      {
        texture: 'map-cloud-2',
        target: 'Cloud_2',
        uid: 'right',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uOffsetParams: { value: new Vector2() },
          uTimeOffset: { value: 0.8 },
          uTime: { value: 0 },
        },
      },
    ],
  },

  doors: {
    bedroom: [
      {
        uid: 'left',
        target: 'porta_1_anta_sx',
        invertAxis: true,
      },
      {
        uid: 'right',
        target: 'porta_1_anta_dx',
        invertAxis: true,
      },
      {
        uid: 'portal',
        target: 'porta_pannello_retro_apertura',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uTime: { value: 0 },
        },
      },
    ],
    makeup: [
      {
        uid: 'left',
        target: 'porta_1_anta_sx',
        invertAxis: true,
      },
      {
        uid: 'right',
        target: 'porta_1_anta_dx',
        invertAxis: true,
      },
      {
        uid: 'portal',
        target: 'porta_pannello_retro_apertura',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uTime: { value: 0 },
        },
      },
    ],
    porch: [
      {
        uid: 'left',
        target: 'porta_1_anta_sx',
        invertAxis: false,
      },
      {
        uid: 'right',
        target: 'porta_1_anta_dx',
        invertAxis: false,
      },
      {
        uid: 'portal',
        target: 'porta_pannello_retro_apertura',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uTime: { value: 0 },
        },
      },
    ],
  },

  opaques: [
    'model_boccetta_flora_gorgeous_jasmine',
    'model_chitarra',
    'model_eyeshadow',
    'Coperchio',
    'model_rossetto',
    'model_flora_gorgeous_gardenia',
    'model_annaffiatoio',
    'porta_1_anta_sx',
    'porta_1_anta_dx',
    'porta_pannello_retro_apertura',
  ],
}
