import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import FullscreenPlaneGeometry from '@/webgl/geometries/FullscreenPlaneGeometry'
import { Mesh, Scene, ShaderMaterial, Vector2, AdditiveBlending } from 'three'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'

class GlowMaterial extends ShaderMaterial {
  constructor() {
    super({
      vertexShader,
      fragmentShader,
      uniforms: {
        uReveal: { value: 0 },
        uStrength: { value: 0 },
        uFlare: { value: new Vector2() },
        uRatio: { value: new Vector2() },
      },
      blending: AdditiveBlending,
      transparent: true,
      depthWrite: false,
      depthTest: false,
    })
  }
}

@Component
export default class Glow extends Vue {
  @Prop()
  strength!: number

  @Prop()
  reveal!: number

  @Prop()
  flare!: any

  @Prop()
  ratio!: any

  @Inject()
  scene!: Scene

  instance = new Mesh(new FullscreenPlaneGeometry(2, 2), new GlowMaterial())

  get uniforms() {
    return this.instance.material.uniforms
  }

  @Watch('strength', { immediate: true })
  strengthUpdate(strength: number) {
    this.uniforms.uStrength.value = strength
  }

  @Watch('flare', { deep: true, immediate: true })
  flareUpdate({ x, y }: any) {
    this.uniforms.uFlare.value.set(x.value * 1000, y.value * 1000)
  }

  @Watch('ratio', { deep: true, immediate: true })
  ratioUpdate({ x, y }: any) {
    this.uniforms.uRatio.value.set(x.value, y.value)
  }

  @Watch('reveal', { immediate: true })
  revealUpdate(reveal: number) {
    this.uniforms.uReveal.value = reveal
  }

  mounted() {
    this.instance.renderOrder = 1

    this.scene.add(this.instance)

    this.$gl.glow = this
  }

  destroyed() {
    this.scene.remove(this.instance)

    this.instance.geometry.dispose()
    this.instance.material.dispose()

    delete this.$gl.glow
  }

  render() {
    return null
  }
}
