const itemSize = 2
const box = { min: [0, 0], max: [0, 0] }

const bounds = (positions: any) => {
  const count = positions.length / itemSize
  box.min[0] = positions[0]
  box.min[1] = positions[1]
  box.max[0] = positions[0]
  box.max[1] = positions[1]

  for (let i = 0; i < count; i++) {
    const x = positions[i * itemSize + 0]
    const y = positions[i * itemSize + 1]
    box.min[0] = Math.min(x, box.min[0])
    box.min[1] = Math.min(y, box.min[1])
    box.max[0] = Math.max(x, box.max[0])
    box.max[1] = Math.max(y, box.max[1])
  }
}

export const computeBox = (positions: any, output: any) => {
  bounds(positions)
  output.min.set(box.min[0], box.min[1], 0)
  output.max.set(box.max[0], box.max[1], 0)
}

export const computeSphere = (positions: any, output: any) => {
  bounds(positions)
  const minX = box.min[0]
  const minY = box.min[1]
  const maxX = box.max[0]
  const maxY = box.max[1]
  const width = maxX - minX
  const height = maxY - minY
  const length = Math.sqrt(width * width + height * height)
  output.center.set(minX + width / 2, minY + height / 2, 0)
  output.radius = length / 2
}

export const lerp = (out: any, a: any, b: any, t: any) => {
  const ax = a[0]
  const ay = a[1]
  out[0] = ax + t * (b[0] - ax)
  out[1] = ay + t * (b[1] - ay)
  return out
}
